<script>
import EventBus from "@/views/event-bus";
import gql from "graphql-tag";

export default {
    name: "host-stats-invoice-sums",
    props: {
        Headline: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            hostId: this.$session.get("hostId"),
            hostStatsGetInvoiceSums: null,
            isLoading: false,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                async function (hostId) {
                    this.hostId = hostId;
                    //this.loadSalesValue();
                }.bind(this)
            );
            //this.loadSalesValue();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    apollo: {
        hostStatsGetInvoiceSums: {
            query: gql`
                query Query($hostId: Int) {
                    hostStatsGetInvoiceSums(hostId: $hostId)
                }
            `,
            variables() {
                // Use vue reactive properties here
                return {
                    hostId: parseInt(this.hostId),
                };
            },
        },
    },
};
</script>

<template lang="pug">
.host-stats-invoicesums.widget
    .card
        .card-body
            .card-title
                .row
                    .col-12
                        .text-left
                            h5 {{ Headline }}
            .card-text.p-4
                .text-center
                    .sales-text(v-if="isLoading") Lädt...
                    h4.sales-text(v-else-if="hostStatsGetInvoiceSums") {{ hostStatsGetInvoiceSums.totalFinalAmount | formatEuro }}

</template>

<style scoped></style>
